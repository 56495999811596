
import {
  alertController,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {mapGetters} from "vuex";
import {saveOutline} from "ionicons/icons";
import EditorCanvas from "@/components/EditorCanvas.vue";
import InvitationDesign from "@/interfaces/InvitationDesign";

interface AdminInvitationDesignEditorData {
  icons: {
    saveOutline: string;
  };
  invitationDesign: InvitationDesign | null;
}
export default defineComponent({
  name: 'AdminInvitationsDesignEditor',
  components: {
    IonFab,
    IonFabButton,
    EditorCanvas,
    IonContent,
    IonPage,
    IonIcon
  },
  computed: {
    ...mapGetters(['invitationDesigns'])
  },
  data(): AdminInvitationDesignEditorData {
    return {
      icons: {
        saveOutline
      },
      invitationDesign: null
    }
  },
  methods: {
    async namePrompt(){
      const confirm = await alertController.create({
        message: 'הכנס שם לעיצוב',
        inputs: [
          {
            name: 'name',
            type: 'text',
            placeholder: 'שם',
            value: this.invitationDesign?.name
          }
        ],
        buttons: [
          {
            text: 'שמירה',
            role: 'save'
          },
          {
            text: 'ביטול',
            role: 'cancel'
          }
        ]
      })

      await confirm.present();
      const {data, role} = await confirm.onDidDismiss();
      if(role === 'save' && data.values.name){
        if(this.invitationDesign){
          this.invitationDesign.name = (data.values.name as string);
        }else{
          this.invitationDesign = {
            name: (data.values.name as string)
          }
        }
        this.canvasSave();
      }
    },
    canvasSave(){
      const canvasRef: any = (this.$refs.editorCanvas as any);
      if(canvasRef){
        canvasRef.saveInvitationDesign();
      }
    },
    save(invitationDesign?: InvitationDesign){
      if(invitationDesign){
        this.invitationDesign = {
          ...this.invitationDesign,
          ...invitationDesign
        };
      }
      this.$store.dispatch('saveInvitationDesign', this.invitationDesign);
    }
  },
  mounted() {
    console.log({
      invitationDesigns: this.invitationDesigns,
      id: this.$route.params.id
    })
    if(this.$route.params.id){
      this.invitationDesign = this.invitationDesigns.find((invitationDesign: InvitationDesign) => invitationDesign.id === this.$route.params.id)
    }
    console.log(this.invitationDesigns)
  }
});
