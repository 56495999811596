import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_canvas = _resolveComponent("editor-canvas")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_editor_canvas, {
            ref: "editorCanvas",
            "invitation-design": _ctx.invitationDesign,
            onSave: _ctx.save
          }, null, 8, ["invitation-design", "onSave"]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "start",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, { onClick: _ctx.namePrompt }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.icons.saveOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}